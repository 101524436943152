import React, { useState, useEffect } from 'react'
import { Tooltip, Button, useToast } from '@chakra-ui/react'
import * as CafUtility from './cafCrf.util'
import CafForm from './CafView/CafForm/cafForm'
import CafFormOld from './CafView/CafForm/cafFormOld'
import IllCAFForm from './CafView/ILLCaf/IllCafForm'
import IllCAFFormOld from './CafView/ILLCaf/IllCafFormOld'
import MplsCRFForm from './CrfView/MplsCrf/CrfForm'
import MplsCRFFormOld from './CrfView/MplsCrf/CrfFormOld'
import IllCRFForm from './CrfView/ILLCrf/IllCrfForm'
import IllCRFFormOld from './CrfView/ILLCrf/IllCrfFormOld'
import NplcCAFForm from './CafView/NplcCaf/NplcCafForm'
import NplcCAFFormOld from './CafView/NplcCaf/NplcCafFormOld'
import PRICAFForm from './CafView/PriCaf/PRICafForm'
import PRICAFFormOld from './CafView/PriCaf/PRICafFormOld'
import PriCRFForm from './CrfView/PriCrf/PriCrfForm'
import PriCRFFormOld from './CrfView/PriCrf/PriCrfFormOld'
import NPLCCRFForm from './CrfView/NplcCrf/NPLCCrfForm'
import NPLCCRFFormOld from './CrfView/NplcCrf/NPLCCrfFormOld'
import SuperWifiCafForm from './CafView/SuperWifiCaf/superWifiCafForm'
import SuperWifiCafFormOld from './CafView/SuperWifiCaf/superWifiCafFormOld'
import IplcCafForm from './CafView/IplcCaf/IplcCafForm'
import IplcCafFormOld from './CafView/IplcCaf/IplcCafFormOld'
import PriSipCafForm from './CafView/PriSipCaf/PriSipCafForm'
import PriSipCafFormOld from './CafView/PriSipCaf/PriSipCafFormOld'
import PriIPCentrixCafForm from './CafView/PreCentricCaf/PriIPCentrixCafForm'
import PriIPCentrixCafFormOld from './CafView/PreCentricCaf/PriIPCentrixCafFormOld'
import ACCafForm from './CafView/AcCaf/AC'
import ACCafFormOld from './CafView/AcCaf/ACOld'
import TFSCafForm from './CafView/TfsCaf/TFS'
import TFSCafFormOld from './CafView/TfsCaf/TFSOld'
import ITFSCafForm from './CafView/ItfsCaf/ITFS'
import ITFSCafFormOld from './CafView/ItfsCaf/ITFSOld'
import VLTCafForm from './CafView/VltCaf/VLT'
import VLTCafFormOld from './CafView/VltCaf/VLTOld'
import VCCafForm from './CafView/VcCaf/VC'
import VCCafFormOld from './CafView/VcCaf/VCOld'
import BulkSMSCafForm from './CafView/BulkSmsCaf/BulkSMS'
import BulkSMSCafFormOld from './CafView/BulkSmsCaf/BulkSMSOld'
import VSDMCafForm from './CafView/VsdmCaf/VSDM'
import VSDMCafFormOld from './CafView/VsdmCaf/VSDMOld'
import NFLCrfForm from './CrfView/NflCrf/CRF'
import NFLCrfFormOld from './CrfView/NflCrf/CRFOld'
import MSSCafForm from './CafView/MssCaf/MssCafForm'
import MSSCafFormOld from './CafView/MssCaf/MssCafFormOld'
import IplcCrfForm from './CrfView/IplcCrf/IplcCrfForm'
import EmailWebCofForm from './CafView/Email&WebSecurity/EmailWebCofForm'
import IplcCrfFormOld from './CrfView/IplcCrf/IplcCrfFormOld'
import GIPTCafForm from './GIPTView/GIPT'
import EthernetCaf from './CafView/Ethernet/EthernetCaf'
import ProformaInvoiceCafForm from './CafView/ProformaInvoice/ProformaInvoiceCaf'
import InvoiceCafForm from './CafView/InvoiceCaf/InvoiceCafForm'
import './CafCrfModalView.scss'
import ReactExport from 'react-data-export'
import { MdPrint } from 'react-icons/md'
import { MdDownload } from 'react-icons/md'
import { RiFileExcel2Line } from 'react-icons/ri'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { useLoadRomsFormFieldListData } from '../../../../hooks/form.hooks'
import { useAuthContext } from '../../../../context/auth.context'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../../repository/repo.utils'
import COLOCofForm from './CofView/ColoCof/ColoCofForm'
import CloudCofForm from './CofView/CloudCof/cloudCofForm'
import CloudCrfForm from './CofView/CloudCrf/CloudCrfForm'
import ColoCrfForm from './CofView/ColoCrf/coloCrfForm'
import SdwanCafForm from './CafView/Sdwancaf/SdwanCafForm'
import HsdwanCrfForm from './CofView/SDWANCrf/HsdwanCrfForm'

import jsPDF from 'jspdf'
import * as htmlToImage from 'html-to-image'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const components = {
	SdwanCafForm: SdwanCafForm,
	ColoCrfForm: ColoCrfForm,
	COLOCofForm: COLOCofForm,
	CloudCofForm: CloudCofForm,
	CloudCrfForm: CloudCrfForm,
	CafForm: CafForm,
	CafFormOld: CafFormOld,
	IllCAFForm: IllCAFForm,
	IllCAFFormOld: IllCAFFormOld,
	MplsCRFForm: MplsCRFForm,
	MplsCRFFormOld: MplsCRFFormOld,
	IllCRFForm: IllCRFForm,
	IllCRFFormOld: IllCRFFormOld,
	NplcCAFForm: NplcCAFForm,
	NplcCAFFormOld: NplcCAFFormOld,
	PRICAFForm: PRICAFForm,
	PRICAFFormOld: PRICAFFormOld,
	PriCRFForm: PriCRFForm,
	PriCRFFormOld: PriCRFFormOld,
	NPLCCRFForm: NPLCCRFForm,
	NPLCCRFFormOld: NPLCCRFFormOld,
	SuperWifiCafForm: SuperWifiCafForm,
	SuperWifiCafFormOld: SuperWifiCafFormOld,
	IplcCafForm: IplcCafForm,
	IplcCafFormOld: IplcCafFormOld,
	PriSipCafForm: PriSipCafForm,
	PriSipCafFormOld: PriSipCafFormOld,
	PriIPCentrixCafForm: PriIPCentrixCafForm,
	PriIPCentrixCafFormOld: PriIPCentrixCafFormOld,
	ACCafForm: ACCafForm,
	ACCafFormOld: ACCafFormOld,
	TFSCafForm: TFSCafForm,
	TFSCafFormOld: TFSCafFormOld,
	ITFSCafForm: ITFSCafForm,
	ITFSCafFormOld: ITFSCafFormOld,
	VLTCafForm: VLTCafForm,
	VLTCafFormOld: VLTCafFormOld,
	VCCafForm: VCCafForm,
	VCCafFormOld: VCCafFormOld,
	BulkSMSCafForm: BulkSMSCafForm,
	BulkSMSCafFormOld: BulkSMSCafFormOld,
	VSDMCafForm: VSDMCafForm,
	VSDMCafFormOld: VSDMCafFormOld,
	NFLCrfForm: NFLCrfForm,
	NFLCrfFormOld: NFLCrfFormOld,
	MSSCafForm: MSSCafForm,
	MSSCafFormOld: MSSCafFormOld,
	IplcCrfForm: IplcCrfForm,
	IplcCrfFormOld: IplcCrfFormOld,
	EmailWebCofForm: EmailWebCofForm,
	GIPTCafForm: GIPTCafForm,
	EthernetCAFForm: EthernetCaf,
	InvoiceCafForm: InvoiceCafForm,
	ProformaInvoiceCafForm: ProformaInvoiceCafForm,
	HsdwanCrfForm: HsdwanCrfForm,
}

const CafCrfModalView = ({
	formData,
	activity,
	romsActivity,
	submittedForms,
}) => {
	const [isFormSubmited, setisFormSubmited] = useState(true)
	const [cafDataupdated, setUpdatedCafData] = useState(null)
	const { mutate } = useLoadRomsFormFieldListData()

	let componentRef = React.useRef()
	const toast = useToast()

	const {
		state: { authData },
	} = useAuthContext()

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setUpdatedCafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	useEffect(() => {
		fetchFieldListData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	let dataSet = []
	if (cafDataupdated) {
		let x = cafDataupdated
		let i
		for (i in x) {
			dataSet.push({
				label: x[i].label,
				value: x[i].value,
			})
		}
	}
	let isOldView =
		CafUtility.getBranding(activity.activity_datetime_created) ===
		CafUtility.CAF_VERSION.OLD_BRANDING
			? true
			: false

	let RomsComponent = isOldView
		? components[romsActivity.old_file_name]
		: components[romsActivity.new_file_name]

	let viewModalType = null
	if (isFormSubmited) {
		viewModalType = (
			<RomsComponent
				queueData={activity}
				formData={formData}
				submittedForms={submittedForms}
				romsActivity={romsActivity}
			/>
		)
	} else {
		viewModalType = (
			<div style={{ margin: '40px', textAlign: 'center' }}>
				Form is not submited.
			</div>
		)
	}

	const handleDownload = async () => {
		let pages = document.querySelectorAll('page')
		var pdf = new jsPDF('p', 'mm', 'a4')
		var width = pdf.internal.pageSize.getWidth()
		var height = pdf.internal.pageSize.getHeight()
		const id = toast({
			title: 'Downloading...',
			description: "Please don't close the document",
			status: 'info',
			duration: null,
			isClosable: false,
		})
		try {
			for (var i = 0; i < pages.length; i++) {
				const dataUrl = await htmlToImage.toPng(pages[i])
				pdf.addImage(dataUrl, 'PNG', 0, 0, width, height)
				if (i < pages.length - 1) pdf.addPage()
			}
			pdf.save('Download.pdf')
			toast.update(id, {
				title: 'Completed',
				description: '',
				duration: 2000,
				isClosable: true,
				status: 'success',
			})
		} catch (error) {
			toast.update(id, {
				title: 'Error',
				description: error.message,
				duration: 2000,
				isClosable: true,
				status: 'error',
			})
		}
	}

	return (
		<div className='cafcrf-view-container'>
			<div className='cafcrf-title-container'>
				<span className='cafcrf-title'>{activity.activity_title}</span>
				<div className='cafcrf-title-action'>
					<Tooltip label='Download' placement='bottom'>
						<Button
							borderRadius='sm'
							size='sm'
							colorScheme={localStorage.getItem('color')}
							variant='solid'
							onClick={e => {
								handleDownload(e)
							}}
							ml={3}
						>
							<MdDownload color='#fff' size='20px' />
						</Button>
					</Tooltip>
					<ReactToPrint content={() => componentRef}>
						<PrintContextConsumer>
							{({ handlePrint }) => (
								<Tooltip label='Print' placement='bottom'>
									<button
										className='btn'
										onClick={e => {
											sectionDetailedTrack({
												category: GA_CATEGORY_DIALOG_BOX,
												action: 'Caf Crf Modal',
												label: 'Print',
											})
											handlePrint(e)
										}}
										data-tip='Print'
									>
										<MdPrint color='#fff' size='20px' />
									</button>
								</Tooltip>
							)}
						</PrintContextConsumer>
					</ReactToPrint>
					<ExcelFile
						element={
							<Tooltip label='Export To Excel' placement='bottom'>
								<button className='btn' data-tip='Export To Excel'>
									<RiFileExcel2Line color='#fff' size='20px' />
									{/* Export to Excel */}
								</button>
							</Tooltip>
						}
						filename={activity.activity_title}
					>
						<ExcelSheet data={dataSet} name={activity.activity_title}>
							<ExcelColumn label='Name' value='label' />
							<ExcelColumn label='Value' value='value' />
						</ExcelSheet>
					</ExcelFile>
				</div>
			</div>
			<div className='cafcrf-overview-container'>
				<div id='crf-modal-view' ref={el => (componentRef = el)}>
					{' '}
					{viewModalType}
				</div>
			</div>
		</div>
	)
}

export default CafCrfModalView
