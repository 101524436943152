import React from 'react'
import 'leaflet/dist/leaflet.css'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { Icon } from 'leaflet'
import marker from '../../assets/icons/map-icon.png'
import { useDisclosure } from '@chakra-ui/hooks'
const myIcon = new Icon({
  iconUrl: marker,
  iconSize: [35, 35],
})
const Mapcomponent = ({ lat, lng, setMapOpenStatus }) => {
  const { isOpen, onClose } = useDisclosure()

  const position = [lat, lng]

  const onpopClose = () => {
    setMapOpenStatus(false)
    onClose()
  }
  return (
    <Modal isOpen={true} onClose={onpopClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MapContainer
            center={position}
            zoom={13}
            attributionControl={false}
            scrollWheelZoom={false}
            //zoomControl={false}
          >
            <TileLayer
              attribution='<a href="https://www.openstreetmap.org/copyright"></a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={myIcon}></Marker>
          </MapContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default Mapcomponent
