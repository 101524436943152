/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../VodafoneIdea.scss';
import vilLogoRed from '../../../../../../assets/images/vil-red-logo.png';
import { JsonToTable } from 'react-json-to-table';
import './EmailWebCof.css';
import {
  useLoadRomsFormFieldListData,
  useLoadCompanySeal,
  useLoadFieldValues,
  useExcelJsonList,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { Spinner, Center, useTheme } from '@chakra-ui/react';
import { encode } from '../../cafCrf.util';

const EmailWebCofForm = ({
  formData,
  queueData,
  romsActivity,
  submittedForms,
}) => {
  const [cafData, setCafData] = useState(false);
  const [emailWebCofData, setUpdatedCafData] = useState(null);
  const [companySeal, setCompanySeal] = useState(null);
  const [authorizedSignatoryName, setAuthSignName] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null);
  const [excelJson, setexcelJson] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadOrderFormValues } = useLoadFieldValues();
  const { mutate: loadExcelJsonValues } = useExcelJsonList();
  const { mutate: loadCompanySeal } = useLoadCompanySeal();
  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCafData(false);
          setUpdatedCafData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    );
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          if (res.length > 0) {
            let ExcelIndex = res[0].activity_inline_data.findIndex(
              res1 => res1.field_id === 313857
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl = res[0].activity_inline_data[ExcelIndex].field_value;
            }
            if (!!excelUrl) {
              loadExcelJsonValues(
                { bucket_url: excelUrl },
                {
                  onSuccess: async result => {
                    setexcelJson(JSON.parse(result));
                  },
                }
              );
            }
          }
        },
      }
    );
  };

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 51039,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = '';
          let authorizedSignatoryName = '';
          let data = [];
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted;
          } else {
            data = [];
          }
          data.forEach(function (fieldObj, index) {
            if (Number(fieldObj.field_id) === 313859) {
              companySeal = fieldObj.field_value;
            }
            if (Number(fieldObj.field_id) === 313858) {
              authorizedSignatoryName = fieldObj.field_value;
            }
          });
          setCompanySeal(companySeal);
          setAuthSignName(authorizedSignatoryName);
        },
      }
    );
  };

  useEffect(() => {
    setCafData(true);
    fetchCompanySeal();
    fetchFieldListData();
    fetchOrderFormValues();
  }, []);

  useEffect(() => {
    let data = emailWebCofData;
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[313953].value) {
        // manager sign url
        let acctManagerSignUrl = data[313953].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
          setacctMngSignImageType(type);
        });
      }
    }
  }, [emailWebCofData, companySeal, authorizedSignatoryName]);

  let emailWebCofView = '';
  if (!!emailWebCofData) {
    let firstCaps = '';
    let secondBold;
    let Signcss;
    if (!!authorizedSignatoryName) {
      let customerSignSplit = authorizedSignatoryName.split(' ');

      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
        secondBold = customerSignSplit['1'];
        Signcss = 'signate_img input_sign';
      } else {
        firstCaps = customerSignSplit['0'];
        secondBold = '';
        Signcss = 'signate_img input_sign under_line';
      }
      //   console.log(firstCaps,"console.log(firstCaps);");
    }

    emailWebCofView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div class="section2">
              <div class="font8">
                <div class="over_hid">
                  <div class="width110px fleft">
                    <img src={vilLogoRed} />{' '}
                  </div>
                  <div class="width50 fleft">
                    <p class="customfom margin0">
                      {' '}
                      <strong>Customer Order Form - Cloud Security</strong>{' '}
                    </p>
                    <p class="margin2 font10">
                      <strong>Email / Web Security Service</strong>
                    </p>
                    <p class="margin0 font10">
                      <strong>Vi™ business</strong>
                    </p>
                  </div>

                  <div class="width30 pad_5t flright">
                    <div class="caf">
                      COF ID
                      <input
                        class="form_col"
                        type="text"
                        name=""
                        value={emailWebCofData[313880].value}
                        readOnly
                      />
                    </div>
                    <div class="caf_inner">
                      {' '}
                      <strong> Account Code</strong>
                      <input
                        class="form_col no_top"
                        type="text"
                        name=""
                        value={emailWebCofData[313881].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="w_85">
                  <div class="over_hid mar_t-5">
                    <div class="pd_t5">
                      <strong>Company(Customer) Name</strong>{' '}
                      <span class="pad_l10">
                        <input
                          class="form_col_input"
                          type="text"
                          name=""
                          value={emailWebCofData[313979].value}
                          readOnly
                        />
                      </span>
                    </div>
                  </div>
                  <p class="">
                    <strong>Authorised Signatory Details</strong>
                  </p>
                </div>
                <div class="over_hid auth">
                  <div class="fleft width45">
                    Name
                    <input
                      class="form_col width85"
                      type="text"
                      name=""
                      value={emailWebCofData[313883].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width25">
                    Contact No:
                    <input
                      class="form_col width59"
                      type="text"
                      name=""
                      value={emailWebCofData[313884].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width30">
                    Email:
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313885].value}
                      readOnly
                    />
                  </div>
                </div>
                <p class="font5">
                  I/We hereby order for VIL MPLS Service subject to terms and
                  conditions as described in the service order form. We furnish
                  the necessary details as fallow.
                </p>
                <p class="ligthbg">Customer Address</p>
                <p class="auth">
                  Primary Contact Name
                  <input
                    class="form_col width60"
                    type="text"
                    name=""
                    value={emailWebCofData[313889].value}
                    readOnly
                  />
                </p>
                <div class="auth over_hid">
                  <div class="fleft width50">
                    Designation
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313890].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width50">
                    Dept. Name
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313891].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth margin0">
                  <span class="width54px">Address</span>
                  <input
                    class="form_col width90"
                    type="text"
                    name=""
                    value={emailWebCofData[313892].value}
                    readOnly
                  />
                </div>
                <div class="auth pad_b5">
                  <span class="width54px"> </span>
                  <input
                    class="form_col width90"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div class="auth over_hid">
                  <div class="fleft width50">
                    City/Village/Post office
                    <input
                      class="form_col width65"
                      type="text"
                      name=""
                      value={emailWebCofData[313893].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width25">
                    Pincode
                    <input
                      class="form_col width65"
                      type="text"
                      name=""
                      value={emailWebCofData[313894].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width25">
                    Telephone
                    <input
                      class="form_col width65"
                      type="text"
                      name=""
                      value={emailWebCofData[313954].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth over_hid">
                  <div class="fleft width58">
                    Landmark
                    <input
                      class="form_col width84"
                      type="text"
                      name=""
                      value={emailWebCofData[313895].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width42">
                    <span class="faxn">Fax No.</span>
                    <input
                      class="form_col width66"
                      type="text"
                      name=""
                      value={emailWebCofData[313896].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth over_hid">
                  <div class="fleft width58">
                    Contact Email Id
                    <input
                      class="form_col width77"
                      type="text"
                      name=""
                      value={emailWebCofData[313897].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width42">
                    Mobile No / Alt No.
                    <input
                      class="form_col width66"
                      type="text"
                      name=""
                      value={emailWebCofData[313898].value}
                      readOnly
                    />
                  </div>
                </div>
                <p class="ligthbg">Customer Technical Contact Details</p>
                <p class="auth">
                  Technical; Contact Name
                  <input
                    class="form_col width60"
                    type="text"
                    name=""
                    value={emailWebCofData[313900].value}
                    readOnly
                  />
                </p>
                <div class="auth over_hid">
                  <div class="fleft width50">
                    Designation
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313901].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width50">
                    Dept. Name
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313902].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth margin0">
                  <span class="width54px">Address</span>
                  <input
                    class="form_col width90"
                    type="text"
                    name=""
                    value={emailWebCofData[313903].value}
                    readOnly
                  />
                </div>
                <div class="auth pad_b5">
                  <span class="width54px"> </span>
                  <input
                    class="form_col width60"
                    type="text"
                    name=""
                    value=""
                  />
                  <span class="width25">
                    Telephone
                    <input
                      class="form_col width24"
                      type="text"
                      name=""
                      value={emailWebCofData[313904].value}
                      readOnly
                    />
                  </span>
                </div>

                <div class="auth over_hid">
                  <div class="fleft width58">
                    Contact Email Id
                    <input
                      class="form_col width77"
                      type="text"
                      name=""
                      value={emailWebCofData[313905].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width42">
                    Mobile No / Alt No.
                    <input
                      class="form_col width66"
                      type="text"
                      name=""
                      value={emailWebCofData[313906].value}
                      readOnly
                    />
                  </div>
                </div>

                <p class="ligthbg">CUSTOMER INFORMATION</p>
                <div class="check over_hid custype">
                  <div class="fleft width15_2 pad_7t">Customer Type</div>
                  <div class="fleft width14">
                    {emailWebCofData[313908].value === 'SA' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}{' '}
                    SA
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313908].value === 'SOHO' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}{' '}
                    SOHO
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313908].value === 'Channels & SOHO' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Channels & SOHO
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313908].value === 'Carrier' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Carrier
                  </div>
                </div>
                <div class="check over_hid custype">
                  <div class="fleft width15_2 pad_7t">
                    Customer Vertical Type
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Automotive' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Automotive
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Consumer Goods' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Consumer Goods
                  </div>
                  <div class="fleft width14 font9">
                    {emailWebCofData[313909].value ===
                    'Diversified & Projects' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Diversified & Projects
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Education' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Education
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Engineering' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Engineering
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Retail' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Retail
                  </div>
                </div>
                <div class="check over_hid custype">
                  <div class="fleft width15_2 pad_7t"> </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Health Care' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Health Care
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Services' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Services
                  </div>
                  <div class="fleft width14 fnt8">
                    {emailWebCofData[313909].value === 'IT & Telecom' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    IT & Telecom
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Logistics' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Logistics
                  </div>

                  <div class="fleft">
                    {emailWebCofData[313909].value ===
                    'Manufacturing & Processing' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Manufacturing & Processing
                  </div>
                </div>
                <div class="check over_hid custype">
                  <div class="fleft width15_2 pad_7t"> </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Trading' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Trading
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Projects EPC' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Projects EPC
                  </div>
                  <div class="fleft width14 font9">
                    {emailWebCofData[313909].value ===
                    'Industry Associations' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Industry Associations
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Finance' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Finance
                  </div>
                  <div class="fleft width14">
                    {emailWebCofData[313909].value === 'Others' ? (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        class="form_col"
                        type="checkbox"
                        name=""
                        value=""
                      />
                    )}
                    Others
                  </div>
                </div>

                <p class="ligthbg">Billing Address</p>
                <p class="margin0">This Address is for billing</p>
                <p class="auth">
                  Contact Person name
                  <input
                    class="form_col width83"
                    type="text"
                    name=""
                    value={emailWebCofData[313913].value}
                    readOnly
                  />
                </p>
                <div class="auth margin0 over_hid">
                  <div class="fleft width50">
                    Designation
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313914].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width50">
                    Dept. Name
                    <input
                      class="form_col width80"
                      type="text"
                      name=""
                      value={emailWebCofData[313915].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth margin0">
                  <span class="width54px">Address</span>
                  <input
                    class="form_col width90"
                    type="text"
                    name=""
                    value={emailWebCofData[313916].value}
                    readOnly
                  />
                </div>
                <div class="auth pad_b5">
                  <span class="width54px"> </span>
                  <input
                    class="form_col width90"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div class="auth margin0 over_hid">
                  <div class="fleft width50">
                    City/Village/Post office
                    <input
                      class="form_col width65"
                      type="text"
                      name=""
                      value={emailWebCofData[313917].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width25">
                    Pincode
                    <input
                      class="form_col width65"
                      type="text"
                      name=""
                      value={emailWebCofData[313918].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width25">
                    Telephone
                    <input
                      class="form_col width65"
                      type="text"
                      name=""
                      value={emailWebCofData[313919].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth margin0 over_hid">
                  <div class="fleft width58">
                    Landmark
                    <input
                      class="form_col width84"
                      type="text"
                      name=""
                      value={emailWebCofData[313920].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width42">
                    <span class="faxn">Fax </span>
                    <input
                      class="form_col width66"
                      type="text"
                      name=""
                      value={emailWebCofData[313921].value}
                      readOnly
                    />
                  </div>
                </div>
                <div class="auth margin0 over_hid">
                  <div class="fleft width58">
                    Contact Email Id
                    <input
                      class="form_col width77"
                      type="text"
                      name=""
                      value={emailWebCofData[313922].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width42">
                    Mobile No / Alt No.
                    <input
                      class="form_col width66"
                      type="text"
                      name=""
                      value={emailWebCofData[313923].value}
                      readOnly
                    />
                  </div>
                </div>

                <div class=" margin0 over_hid">
                  <div class="fleft auth width40">
                    PAN NUMBER
                    <input
                      class="form_col width60"
                      type="text"
                      name=""
                      value={emailWebCofData[313924].value}
                      readOnly
                    />
                  </div>
                  <div class="fleft width60 check">
                    <div class="fleft pad_5t ">GST TYPE</div>

                    <div class="fleft pad_l_r10">
                      {emailWebCofData[313925].value === 'Normal GSTIN' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Normal GSTIN
                    </div>
                    <div class="fleft">
                      {' '}
                      SEZ
                      {emailWebCofData[313925].value === 'SEZ' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                    </div>
                    <div class="fleft pad_l_r10">
                      {' '}
                      Not registered Under GST
                      {emailWebCofData[313925].value ===
                      'Not registered under GST' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class=" margin0 over_hid">
                  <div class="fleft auth width40">
                    GST NUMBER
                    <input
                      class="form_col width60"
                      type="text"
                      name=""
                      value={emailWebCofData[313926].value}
                      readOnly
                    />
                  </div>
                </div>
                <div style={{ width: '90rem' }}>
                  <div
                    className="signate float_img"
                    // style={{ bottom: "20px" }}
                  >
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}

                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </page>

          <page size="A4">
            <div class="section2">
              <div class="font8">
                <div class="over_hid">
                  <p class="ligthbg">Order Details</p>

                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t"> Product </div>
                    <div class="fleft width16">
                      {emailWebCofData[313928].value === 'Email' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Email{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313928].value === 'Web' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Web{' '}
                    </div>
                  </div>

                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t"> Type of Order </div>
                    <div class="fleft width16">
                      {emailWebCofData[313929].value === 'New' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      New{' '}
                    </div>
                    <div class="fleft">
                      {['Change', 'Demo - Paid', 'Demo - Free'].includes(
                        emailWebCofData[313929].value
                      ) ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      (Upgrade/Downgrade/Price revision){' '}
                    </div>
                  </div>
                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t"> Contract Period </div>
                    <div className="fleft width30">
                      <input
                        className="form_col wid120p input_red"
                        type="text"
                        name=""
                        value={emailWebCofData[313976].value}
                        readOnly
                      />
                      <span className="mnth">Months </span>
                    </div>
                    {/* <div class="fleft"> */}
                    <div class="fleft width14 pad_7t"> YOY Discount</div>
                    <div className="fleft width30">
                      <input
                        className="form_col wid120p input_red"
                        type="text"
                        name=""
                        value={emailWebCofData[313977].value}
                        readOnly
                      />
                    </div>
                    {/* </div> */}
                  </div>

                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t">
                      {' '}
                      Billing Periodicity{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313932].value === 'Monthly' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Monthly{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313932].value === 'Quarterly' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Quarterly{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313932].value === 'Half-Yearly' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Half Yearly{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313932].value === 'Annually' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Annually{' '}
                    </div>
                  </div>
                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t"> Billing in </div>
                    <div class="fleft width16">
                      {emailWebCofData[313933].value === 'Advance' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Advance{' '}
                    </div>
                    {/* <div class="fleft">
                      {emailWebCofData[313933].value === 'Arrear' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Arrear
                    </div> */}
                  </div>
                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t">
                      {' '}
                      Service Package (Email){' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313934].value === 'Essentials' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Essentials{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313934].value === 'Enhanced' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Enhanced{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313934].value === 'Premium' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Premium{' '}
                    </div>
                  </div>

                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t">
                      {' '}
                      Service Package (Web){' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313935].value === 'Essentials' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      Essentials{' '}
                    </div>
                  </div>
                  <div class="check over_hid">
                    <div class="fleft width14 pad_7t"> Currency </div>
                    <div class="fleft width16">
                      {emailWebCofData[313936].value === 'INR' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      INR{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313936].value === 'USD' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      USD{' '}
                    </div>
                    <div class="fleft width16">
                      {emailWebCofData[313936].value === 'EURO' ? (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          class="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                      )}
                      EURO{' '}
                    </div>
                  </div>
                  <p class="ligthbg">Commercial Terms Summary</p>

                  <div class="over_hid pad_t10">
                    <div class="margin0 width30 fleft">
                      <input
                        class="form_col fullwidth inputext_brd_list text-center"
                        type="text"
                        name=""
                        value=""
                        placeholder="One time Charges* "
                      />
                    </div>
                    <div class="margin0 wid30 fleft pad_l50">
                      <input
                        class="form_col inputext_brd_list text-center"
                        type="text"
                        name=""
                        value={emailWebCofData[313955].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="over_hid pad_t10">
                    <div class="margin0 width30 fleft">
                      <input
                        class="form_col fullwidth inputext_brd_list text-center"
                        type="text"
                        name=""
                        value=""
                        placeholder="License Charges per unit*"
                      />
                    </div>
                    <div class="margin0 wid30 fleft pad_l50">
                      <input
                        class="form_col inputext_brd_list text-center"
                        type="text"
                        name=""
                        value={emailWebCofData[313958].value}
                        readOnly
                      />
                    </div>
                    <div class="margin0 wid30 fleft pad_l50">
                      <input
                        class="form_col inputext_brd_list text-center"
                        type="text"
                        name=""
                        value=""
                        placeholder="No. of License"
                      />
                    </div>
                    <div class="margin0 wid30 fleft pad_l20">
                      <input
                        class="form_col inputext_brd_list text-center"
                        type="text"
                        name=""
                        value={emailWebCofData[313960].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="over_hid">
                    <div class="margin0 width30 fleft">
                      <input
                        class="form_col fullwidth inputext_brd_list text-center"
                        type="text"
                        name=""
                        value=""
                        placeholder="Annual Reccurring Charges"
                      />
                    </div>
                    <div class="margin0 wid30 fleft pad_l50">
                      <input
                        class="form_col inputext_brd_list text-center"
                        type="text"
                        name=""
                        value={emailWebCofData[313957].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="over_hid">
                    <div class="margin0 width30 fleft">
                      <input
                        class="form_col fullwidth inputext_brd_list text-center"
                        type="text"
                        name=""
                        value=""
                        placeholder="RC"
                      />
                    </div>
                    <div class="margin0 wid30 fleft pad_l50">
                      <input
                        class="form_col inputext_brd_list text-center"
                        type="text"
                        name=""
                        value={emailWebCofData[313959].value}
                        readOnly
                      />
                    </div>
                    <div class="margin0 wid30 fleft">
                      (Calculated as per opted biliing preodicity)
                    </div>
                    <div class="margin0 wid30 fleft pad_l50">
                      *Exclusive of tax
                    </div>
                  </div>
                  <div class="over_hid">
                    <textarea
                      class="form_col inputext_brd_list areatext"
                      placeholder="Comments/ Special Considerations:"
                      value={emailWebCofData[313941].value}
                      readOnly
                    ></textarea>
                  </div>
                  <div style={{ width: '90rem' }}>
                    <div
                      className="signate float_img"
                      // style={{ bottom: "20px" }}
                    >
                      {firstCaps !== '' ? (
                        <div style={{ padding: '10px' }} className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}

                      {imageData !== null && imageData !== '' ? (
                        <div
                          style={{ padding: '10px' }}
                          className="signate_img"
                        >
                          <img
                            src={
                              imageData !== null
                                ? 'data:' +
                                  imageType +
                                  ';base64,' +
                                  encode(imageData)
                                : ''
                            }
                            alt={'Company Seal'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>

          <page size="A4" className="new-page">
            <div className="section2 font8 ">
              <div className="">
                <div className="over_hid">
                  <div className="fleft width100">
                    <p class="ligthbg font8">TERMS & CONDITIONS </p>
                    
                    <p className="colorblack">Service delivery notification and confrimation will be only communicated to customer over registered email.</p> 																																				
<p className='colorblack'>It is understood that the service activation date (also referred as bill start date) will be communicated by VITSL via an automated system generated email to Customer's registered email address once service is provisioned and ready to use. Customer agrees that they shall be liable to pay for the Services from the service activation date or bill start date, whichever is earlier.</p>																																		
<p className='colorblack'>Parties agreed that the Service Commencement Date for billing of the Service would begin from same day.</p>																																				
<p className='colorblack'>Payment Terms: Monthly or Quarterly or Half yearly or Annual in advance.	</p>																																			
<br />
<p className='colorblack'>Early Termination charges: if Customer terminates the service prior to the end of the Contract Period   then Customer shall be liable to pay 100% early termination charges for the remaining period of the contract.</p>																																		
<p className='colorblack'>Customer should provide VITSL a minimum 90 days prior written notice period before terminating entire/any part of the service	</p>																																			
<p className='colorblack'>In the event the term or usage of a Service extends beyond the agreed expiry date or termination date of this COF, then the term and conditions of this COF shall be extended to the expiration /termination of the applicable service. Customer shall thus be bound to make payment of service to VITSL during such extended period as per last agreed pricing terms. Either Party shall after the term or during the Service extended period have a right to terminate service after giving the other Party prior written notice of thirty (90) days notice.	</p>																																			
<br />
<br />
<br />																																	
<p className='colorblack'>The Customer must ensure that Service provided by VITSL, is not bing used for any unlawful purposes. The Customer shall ensure and indemnify VITSL in case of tany objectionable, obscene, unauthorized or any other content, messages or communications infringing copyright, Intellectual property right is found on the Services availed by the Customer and will ensure compliance to international & domestic cyber laws, in any form or inconsistent with the laws of India, are not carried in their network by them or any other authorized person using their network or during the provisioning of Services. The Customer must at all times take all necessary measures to prevent such use. The use of the Service by Customer or their end user for anti-national activities shall be construed as an offence punishable under the Indian Penal Code or other applicable law.	</p>																																			
<br />
<br />
<br />																																																																																														
<p className='colorblack'>VITSL warrants that the Services shall be of the acceptable grade, consistent with the established and																																				
generally accepted standards. However, the quality, functionality, availability, or reliability of the same																																				
may be affected from time to time.</p>																																				
<br/>																																			
<p className='colorblack'>VITSL may change, amend, or revise the above regulatory terms and conditions at any time as and when																																				
necessary in order to comply with any statutory, legal or regulatory requirements and the Customer																																				
agree to abide by such modified terms and conditions. Such changes, amendments or revisions shall be																																				
deemed effective upon posting an updated and duly dated regulatory compliance to the Customer via																																				
email, fax, post or through any other medium opted by VITSL.</p>																																		
<br />
<br />																													
<p className='colorblack'>Customer has read, understands and agrees to VITSL Privacy Policy which is available at																																				
https://www.MyVi.in/privacy-policy</p>																																
<br />
<br />																																				
<p className='colorblack'>Successors and permitted assignees to the terms & conditions of the COF, along with the Terms &																																				
conditions of MSA & SLA (if any).</p>																																				
<br />
<br />																					
<p className='colorblack'>GST & SEZ STATUS: The Customer will be solely responsible for providing the correct GST number and																																				
SEZ related information against the "Place of Supply" as per the GST law. S/he waives off all the rights to																																				
dispute or withhold any payments in case of incorrect GST Numbers or incorrect SEZ certificates/ status																																				
being provided, which don't match the details available on the Government GSTN portal. The Customer																																				
acknowledges that s/he will not be able to claim the GST tax credit under such circumstances and shall																																				
pay 100% invoiced amount, including taxes.</p>																																		
                  </div>
                 
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div class="section2 font8">
              <div class="over_hid pad_t20">
                <div class="fleft width65 pad_t10">
                  <p class="marb5">
                    Applicant's Name* / Authorised Signatory's Name**
                  </p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name=""
                      value={emailWebCofData[313944].value}
                      readOnly
                    />
                  </p>
                  <div class="over_hid pad_t30">
                    <div class="fleft width40">
                      Designation
                      <input
                        class="form_col width90 inputext design"
                        type="text"
                        name=""
                        value={emailWebCofData[313945].value}
                        readOnly
                      />
                    </div>
                    <div class="fleft width30">
                      Place
                      <input
                        class="form_col width90 inputext design"
                        type="text"
                        name=""
                        value={emailWebCofData[313946].value}
                        readOnly
                      />
                    </div>
                    <div class="fleft width30">
                      Date
                      <input
                        class="form_col width90 inputext design"
                        type="text"
                        name=""
                        value={emailWebCofData[313947].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div class="flright width30">
                  <div style={{ padding: '5px' }} className="signate_account">
                    {firstCaps !== '' ? (
                      <div className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      Signature of the Customer / Authorised signatory with in
                      this box only (In case of institutinal, please affix
                      office/company seal){' '}
                    </div>
                  </div>
                </div>
              </div>
              <div class="over_hid pad_t30">
                <div class="fleft width65 pad_t10">
                  <p class="marb5">Account Manager Name</p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name=""
                      value={emailWebCofData[313949].value}
                      readOnly
                    />
                  </p>
                  <p class="marb5">Account Manager Cluster Office </p>
                  <p class="margin0">
                    <input
                      class="form_col width50 inputext_brd_list"
                      type="text"
                      name=""
                      value={emailWebCofData[313950].value}
                      readOnly
                    />
                  </p>
                  <p class="marb5">Channel Partner Name</p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name=""
                      value={emailWebCofData[313951].value}
                      readOnly
                    />
                  </p>
                  <p class="marb5">Channel Partner Code</p>
                  <p class="margin0">
                    <input
                      class="form_col width50 inputext_brd_list"
                      type="text"
                      name=""
                      value={emailWebCofData[313952].value}
                      readOnly
                    />
                  </p>
                </div>
                <div class="flright width30">
                  <div
                    style={{ padding: '5px', height: '120px' }}
                    className="signate"
                  >
                    {acctMngSignImageData !== null &&
                    acctMngSignImageData !== '' ? (
                      <img
                        height="90px"
                        width="100px"
                        src={
                          acctMngSignImageData !== null
                            ? 'data:' +
                              acctMngSignImageType +
                              ';base64,' +
                              encode(acctMngSignImageData)
                            : ''
                        }
                        alt={'Signature of Account Manager'}
                      />
                    ) : (
                      ''
                    )}
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
              </div>

              <div class="limite">
                {/* <span> Vodafone Idea Limited (VIL) </span>/  */}
                Vodafone Idea Technology Solutions Limited (VITSL)
              </div>
            </div>
          </page>
          {excelJson !== null ? (
            <page size="A4">
              <div>
                <div className="pad_t10 section2">
                  <p className="customfom">
                    Annexure to Email & Web Security COF :-{' '}
                  </p>
                </div>
                <JsonToTable json={excelJson} />
                <div>
                  <p className="font10 " style={{ marginTop: '20px' }}>
                    <strong>Note:</strong> Address proofs will be required for
                    each of the sites listed above as part of standard
                    documentation guidelines; Master CAF referred here should be
                    signed by the same
                  </p>
                </div>

                <div className="fleft width50 pad_t10">
                  <p className="marb5">Account Manager Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      readonly=""
                      value={emailWebCofData[313949].value}
                      readOnly
                    />
                  </p>
                  <div className="signate">
                    <div className="signate_img input_sign">
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
                <div style={{ position: 'absolute', width: '90rem' }}>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div>
                        Authorised Signatory Name
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={emailWebCofData[313944].value}
                          readOnly
                        />
                      </div>
                    </div>
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </page>
          ) : (
            ''
          )}
          {excelJson !== null ? (
            <page size="A4">
              <div>
                <div className="pad_t10 section2">
                  <p className="customfom">
                    Annexure to Email & Web Security COF :-{' '}
                  </p>
                </div>
                <JsonToTable json={excelJson} />
                <div>
                  <p className="font10 " style={{ marginTop: '20px' }}>
                    <strong>Note:</strong> Address proofs will be required for
                    each of the sites listed above as part of standard
                    documentation guidelines; Master CAF referred here should be
                    signed by the same
                  </p>
                </div>

                <div className="fleft width50 pad_t10">
                  <p className="marb5">Account Manager Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      readonly=""
                      value={emailWebCofData[313949].value}
                      readOnly
                    />
                  </p>
                  <div className="signate">
                    <div className="signate_img input_sign">
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
                <div style={{ position: 'absolute', width: '90rem' }}>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div>
                        Authorised Signatory Name
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={emailWebCofData[313944].value}
                          readOnly
                        />
                      </div>
                    </div>
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </page>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else {
    emailWebCofView = cafData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        Email & Web Security COF Form not submitted yet.
      </div>
    );
  }

  return <div>{emailWebCofView}</div>;
};
export default EmailWebCofForm;
